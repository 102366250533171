export enum RoutesEnum {
  HOME = '',
  PRICING = 'pricing',
  ACCOUNTING = 'accounting',
  SERVICES = 'services',
  TRADE = 'inventory-management',
  WAREHOUSE = 'warehouse',
  PRODUCTS = 'products',
  PARTNERS = 'partners',
  TEAM = 'team',
  RETAIL = 'retail',
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_OF_SERVICE = 'terms-of-service',
  COOKIE_POLICY = 'cookie-policy',
  ABOUT = 'about',
  LEGAL = 'legal',
  LEXICON = 'lexicon',
  BLOG = 'blog',
  ACCOUNTING_SOFTWARE = 'solutions-accounting-software',
  INVENTORY_MANAGEMENT = 'solutions-inventory-management-software',
  ECOMMERCE_SOFTWARE = 'solutions-e-commerce-software',
  ECOMMERCE = 'e-commerce-system',
  POINT_OF_SALE = 'solutions-point-of-sale',
  BLOG_PAGE = 'blog/:postName',
  LEXICON_PAGE = 'lexicon/:postName',
  AUTHOR_PAGE = 'author',
  START = 'start',
  ANALYTICS = 'analytics',
  WELCOME = 'welcome',
  TRADE_SEO = 'warenwirtschaftssystem',
  ECOMMERCE_SEO = 'e-commerce-software',
  ACCOUNTING_SEO = 'buchhaltungssoftware',
  RETAIL_SEO = 'retail-seo',
  SERVICE_SEO = 'services-seo',
  ERP_SOFTWARE = 'erp-software',
  RUNPLE_FREE = 'buchhaltungssoftware-kostenlos',
  ONLINE_STORE = 'all-in-one-software',
  CREATE_USER = 'create-user/:token',
  NOT_PAGE = '404',
  OFFER = 'trade-offer',
  MICROSOFT_CONFIRMATION = 'microsoft-confirmation',
  SURVEY = 'survey',
  SAAS = 'system',
  SIGN_IN = 'sign-in',
  WORKSPACE_CREATED = 'workspace',
  RELEASE_NOTES = 'release-notes',
}
