import { LoadChildren, Route, Routes } from '@angular/router';
import { Type } from '@angular/core';

import { RoutesEnum } from './routes.enum';
import { LOCALE_ROUTES_CONFIG } from './locale-routes.config';
import { LocalePrefixesEnum } from './locale-prefixes.enum';


export function getLazyModuleRoutesRoutes(route: RoutesEnum, commonParams: Partial<Route>): Routes {
  const routes: Routes = [];

  // add default route. example: '/home'
  routes.push({
    path: route,
    ...commonParams
  });

  for (const localePrefix in LOCALE_ROUTES_CONFIG[route]) {
    if (LOCALE_ROUTES_CONFIG?.[route]?.hasOwnProperty(localePrefix)) {
      // add route with locale. example: 'en/home'
      if (localePrefix !== LocalePrefixesEnum.EN) {
        routes.push({
          // @ts-ignore
          path: `${localePrefix}/${LOCALE_ROUTES_CONFIG[route][localePrefix]}`,
          ...commonParams
        });
      }
    }
  }

  return routes;
}

// HOME PAGE
export function getHomeRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    path: '',
    data: {
      title: 'Home',
      metaTitle: 'META_TAGS.HOME_TITLE',
      metaDesc: 'META_TAGS.HOME_DESCR',
      isLendingPage: true,
      hasCta: true,
    }
  };
  const routes: Routes = [];

  // add default route. example: '/home'
  routes.push({
    path: '',
    ...commonParams
  });

  Object.values(LocalePrefixesEnum).forEach((locale: LocalePrefixesEnum) => {
    if (locale !== LocalePrefixesEnum.EN) {
      routes.push({
        ...commonParams,
        path: locale
      });
    }
  });
  return routes;
}

// FEATURES PAGES
export function getAccountingRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Accounting',
      metaTitle: 'META_TAGS.ACCOUNTING_TITLE',
      metaDesc: 'META_TAGS.ACCOUNTING_DESCR',
      hasCta: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.ACCOUNTING, commonParams);
}

export function getWarehouseRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Warehouse',
      metaTitle: 'META_TAGS.WAREHOUSE_TITLE',
      metaDesc: 'META_TAGS.WAREHOUSE_DESCR',
      hasCta: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.WAREHOUSE, commonParams);
}

export function getRetailRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'META_TAGS.RETAIL_TITLE',
      metaTitle: 'META_TAGS.RETAIL_TITLE',
      metaDesc: 'META_TAGS.RETAIL_DESCR'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.RETAIL, commonParams);
}

export function getPartnersRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Partners',
      metaTitle: 'META_TAGS.PARTNERS_TITLE',
      metaDesc: 'META_TAGS.PARTNERS_DESCR',
      hasCta: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.PARTNERS, commonParams);
}

export function getProductsRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Products',
      metaTitle: 'META_TAGS.PRODUCTS_TITLE',
      metaDesc: 'META_TAGS.PRODUCTS_DESCR',
      hasCta: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.PRODUCTS, commonParams);
}

export function getTeamRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Team',
      metaTitle: 'META_TAGS.TEAM_TITLE',
      metaDesc: 'META_TAGS.TITLE_DESCR',
      hasCta: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.TEAM, commonParams);
}

export function getServicesRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Services',
      metaTitle: 'META_TAGS.SERVICES_TITLE',
      metaDesc: 'META_TAGS.SERVICES_DESCR',
      hasCta: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.SERVICES, commonParams);
}

export function getTradeRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Wholesale',
      metaTitle: 'META_TAGS.WHOLESALE_TITLE',
      metaDesc: 'META_TAGS.WHOLESALE_DESCR',
      hasCta: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.TRADE, commonParams);
}

export function getEcommerceRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Ecommerce',
      metaTitle: 'META_TAGS.ECOMMERCE_TITLE',
      metaDesc: 'META_TAGS.ECOMMERCE_DESCR',
      hasCta: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.ECOMMERCE, commonParams);
}

export function getAboutRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'About',
      metaTitle: 'META_TAGS.ABOUT_TITLE',
      metaDesc: 'META_TAGS.ABOUT_DESCR',
      hasCta: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.ABOUT, commonParams);
}

export function getAnalyticsRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Analytics',
      metaTitle: 'META_TAGS.ANALYTICS_TITLE',
      metaDesc: 'META_TAGS.ANALYTICS_DESCR'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.ANALYTICS, commonParams);
}

export function get404Routes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - 404',
      metaTitle: 'Runple - 404',
      metaDesc: 'Runple - 404',
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.NOT_PAGE, commonParams);
}


// SOLUTIONS PAGES
export function getAccountingSoftwareRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Accounting software',
      metaTitle: 'META_TAGS.SOLUTION_ACCOUNTING_TITLE',
      metaDesc: 'META_TAGS.SOLUTION_ACCOUNTING_DESCR',
      isLendingPage: true,
      hasCta: true,
      noindex: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.ACCOUNTING_SOFTWARE, commonParams);
}

export function getInventoryManagementRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - inventory management software',
      metaTitle: 'META_TAGS.SOLUTION_TRADE_TITLE',
      metaDesc: 'META_TAGS.SOLUTION_TRADE_DESCR',
      isLendingPage: true,
      hasCta: true,
      noindex: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.INVENTORY_MANAGEMENT, commonParams);
}

export function getEcommerceSoftwareRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - e-commerce software',
      metaTitle: 'META_TAGS.SOLUTION_ECOMMERCE_TITLE',
      metaDesc: 'META_TAGS.SOLUTION_ECOMMERCE_DESCR',
      isLendingPage: true,
      hasCta: true,
      noindex: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.ECOMMERCE_SOFTWARE, commonParams);
}

export function getPointOfSaleRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - point of sale',
      metaTitle: 'META_TAGS.SOLUTION_POINT_OF_SALE_TITLE',
      metaDesc: 'META_TAGS.SOLUTION_POINT_OF_SALE_DESCR',
      noindex: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.POINT_OF_SALE, commonParams);
}

// SEO PAGES
export function getTradeSeoRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'trade seo',
      metaTitle: 'META_TAGS.TRADE_SEO_PAGE_TITLE',
      metaDesc: 'META_TAGS.TRADE_SEO_PAGE_DESCR',
      hasCta: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.TRADE_SEO, commonParams);
}

export function getEcommerceSeoRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'META_TAGS.ECOMMERCE_SEO_PAGE_TITLE',
      metaTitle: 'META_TAGS.ECOMMERCE_SEO_PAGE_TITLE',
      metaDesc: 'META_TAGS.ECOMMERCE_SEO_PAGE_DESCR',
      hasCta: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.ECOMMERCE_SEO, commonParams);
}

export function getErpSoftwareRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'META_TAGS.ERP_SOFTWARE_SEO_TITLE',
      metaTitle: 'META_TAGS.ERP_SOFTWARE_SEO_TITLE',
      metaDesc: 'META_TAGS.ERP_SOFTWARE_SEO_DESCR',
      hasCta: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.ERP_SOFTWARE, commonParams);
}

export function getOnlineStoreSeoRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'META_TAGS.ONLINE_STORE_SEO_PAGE_TITLE',
      metaTitle: 'META_TAGS.ONLINE_STORE_SEO_PAGE_TITLE',
      metaDesc: 'META_TAGS.ONLINE_STORE_SEO_PAGE_DESCR',
      hasCta: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.ONLINE_STORE, commonParams);
}

export function getAccountingSeoRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'META_TAGS.ACCOUNTING_SEO_TITLE',
      metaTitle: 'META_TAGS.ACCOUNTING_SEO_TITLE',
      metaDesc: 'META_TAGS.ACCOUNTING_SEO_DESCR',
      hasCta: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.ACCOUNTING_SEO, commonParams);
}

export function getRunpleFreeSeoRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'META_TAGS.RUNPLE_FREE_TITLE',
      metaTitle: 'META_TAGS.RUNPLE_FREE_TITLE',
      metaDesc: 'META_TAGS.RUNPLE_FREE_DESCR',
      hasCta: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.RUNPLE_FREE, commonParams);
}

// BLOG LEXICON PAGES
export function getBlogRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Blog',
      metaTitle: 'META_TAGS.BLOG_TITLE',
      metaDesc: 'META_TAGS.BLOG_TITLE'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.BLOG, commonParams);
}

export function getBlogPageRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Blog',
      metaTitle: 'META_TAGS.BLOG_TITLE',
      metaDesc: 'META_TAGS.BLOG_TITLE'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.BLOG_PAGE, commonParams);
}

export function getLexiconRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - lexicon',
      metaTitle: 'META_TAGS.LEXICON_TITLE',
      metaDesc: 'META_TAGS.BLOG_TITLE'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.LEXICON, commonParams);
}

export function getLexiconPageRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - lexicon',
      metaTitle: 'META_TAGS.LEXICON_TITLE',
      metaDesc: 'META_TAGS.BLOG_TITLE'
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.LEXICON_PAGE, commonParams);
}

export function getAuthorRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - lexicon',
      metaTitle: 'META_TAGS.BLOG_TITLE',
      metaDesc: 'META_TAGS.BLOG_TITLE'
    }
  };
  const routes: Routes = [];

  for (const localePrefix in LOCALE_ROUTES_CONFIG[RoutesEnum.AUTHOR_PAGE]) {
    if (LOCALE_ROUTES_CONFIG?.[RoutesEnum.AUTHOR_PAGE]?.hasOwnProperty(localePrefix)) {
      // add route with locale. example: 'en/home'
      if (localePrefix !== LocalePrefixesEnum.EN) {
        routes.push({
          // @ts-ignore
          path: `${localePrefix}/${LOCALE_ROUTES_CONFIG[RoutesEnum.AUTHOR_PAGE][localePrefix]}/:authorName`,
          ...commonParams
        });
      }
    }
  }

  return routes;
}

// SECONDARY PAGES
export function getCookiePolicyRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Cookie policy',
      metaTitle: 'META_TAGS.COOKIES_POLICY_TITLE',
      metaDesc: 'META_TAGS.COOKIES_POLICY_DESCR',
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.COOKIE_POLICY, commonParams);
}

export function getLegalRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      metaTitle: 'META_TAGS.LEGAL_NOTICE_TITLE',
      metaDesc: 'META_TAGS.LEGAL_NOTICE_DESCR',
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.LEGAL, commonParams);
}

export function getPrivacyPolicyRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Privacy policy',
      metaTitle: 'META_TAGS.PRIVACY_POLICY_TITLE',
      metaDesc: 'META_TAGS.PRIVACY_POLICY_DESCR',
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.PRIVACY_POLICY, commonParams);
}

export function getTermsOfServicesRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Terms of service',
      metaTitle: 'META_TAGS.TERMS_OF_SERVICE_TITLE',
      metaDesc: 'META_TAGS.TERMS_OF_SERVICE_DESCR',
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.TERMS_OF_SERVICE, commonParams);
}

export function getSaasRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'META_TAGS.SYSTEM',
      metaTitle: 'META_TAGS.SYSTEM',
      metaDesc: 'META_TAGS.SYSTEM',
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.SAAS, commonParams);
}

export function getReleaseNotesRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'META_TAGS.RELEASE_NOTES',
      metaTitle: 'META_TAGS.RELEASE_NOTES',
      metaDesc: 'META_TAGS.RELEASE_NOTES',
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.RELEASE_NOTES, commonParams);
}

// PRICE PAGE
export function getPricingRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - Pricing',
      metaTitle: 'META_TAGS.PRICING_TITLE',
      metaDesc: 'META_TAGS.PRICING_DESCR',
      hideRequestDemo: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.PRICING, commonParams);
}

// SIGN UP PAGES
export function getSignUpRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple - sign up',
      metaTitle: 'META_TAGS.SIGN_UP_TITLE',
      metaDesc: 'META_TAGS.SIGN_UP_DESCR',
      hideRequestDemo: true,
      hideToTopButton: true,
      hasCta: true,
      hideStartToFreeAction: true
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.START, commonParams);
}

export function getCreateUserRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      metaTitle: 'META_TAGS.WELCOME',
      metaDesc: 'META_TAGS.WELCOME',
      isExternalPage: true,
      hideRequestDemo: true,
      hideToTopButton: true,
      noindex: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.CREATE_USER, commonParams);
}

export function getWorkspaceCreatedRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      metaTitle: 'META_TAGS.WELCOME',
      metaDesc: 'META_TAGS.WELCOME',
      isExternalPage: true,
      hideRequestDemo: true,
      hideToTopButton: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.WORKSPACE_CREATED, commonParams);
}

export function getSignInRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      metaTitle: 'META_TAGS.SIGN_IN',
      metaDesc: 'META_TAGS.SIGN_IN',
      hideRequestDemo: true,
      hideToTopButton: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.SIGN_IN, commonParams);
}

export function getWelcomePage(component: Type<any>): Routes {
  const defaultRoute: Route = { path: RoutesEnum.WELCOME, component };
  const routes: Routes = [defaultRoute];
  Object.values(LocalePrefixesEnum).forEach((locale: LocalePrefixesEnum) => {
    if (locale !== LocalePrefixesEnum.EN) {
      routes.push({
        ...defaultRoute,
        path: `${LOCALE_ROUTES_CONFIG?.[RoutesEnum.WELCOME]?.[locale]}`,
        data: {
          title: '',
          metaTitle: '',
          metaDesc: '',
        }
      });
    }
  });
  return routes;
}

// EXTERNAL PAGES

export function getTradeOfferRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple',
      metaTitle: 'Runple offer',
      metaDesc: 'Runple offer',
      isExternalPage: true,
      hideRequestDemo: true,
      hideToTopButton: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.OFFER, commonParams);
}

export function getMicrosoftConfirmationRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple',
      metaTitle: 'Runple',
      metaDesc: 'Runple',
      isExternalPage: true,
      hideRequestDemo: true,
      hideToTopButton: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.MICROSOFT_CONFIRMATION, commonParams);
}

export function getFeedbackRoutes(loadChildren: LoadChildren): Routes {
  const commonParams: Partial<Route> = {
    loadChildren,
    data: {
      title: 'Runple',
      metaTitle: 'Runple feedback',
      metaDesc: 'Runple feedback',
      isExternalPage: true,
      hideRequestDemo: true,
      hideToTopButton: true,
    }
  };
  return getLazyModuleRoutesRoutes(RoutesEnum.SURVEY, commonParams);
}
